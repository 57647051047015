<template lang="pug">
.singleSlider.layout
  .singleSlider-counter {{ currentSlide + 1 }} / {{ images && images.length }}
  .singleSlider-close(@click='$emit("closeSlider")'): SvgComponent(name='close')
  .singleSlider-arrow._left(@click='changeSlide(-1)'): SvgComponent(name='arrow-circle')
  img.singleSlider-image(
    alt,
    v-for='(image, i) in images',
    :src='getImgUrl(image)',
    v-show='i == currentSlide',
    :class='{ _active: i == currentSlide }'
  )
  .singleSlider-arrow._right(@click='changeSlide(1)'): SvgComponent(name='arrow-circle')
  .singleSlider-counter
</template>

<script>
  import SvgComponent from '@/components/SvgComponent.vue';

  export default {
    data() {
      return {
        currentSlide: 0,
      };
    },
    methods: {
      getImgUrl(image) {
        const path = this.$store.state.desktop
          ? image.formats.large?.url || image.url
          : image.formats.medium?.url || image.url;
        return this.$hostname + path;
      },
      changeSlide: function (param) {
        if (this.currentSlide + param == -1) {
          this.currentSlide = this.images.length - 1;
        } else if (this.currentSlide + param >= this.images.length) {
          this.currentSlide = 0;
        } else {
          this.currentSlide += param;
        }
      },
    },
    components: {
      SvgComponent,
    },
    props: {
      images: Array,
    },
  };
</script>

<style lang="scss">
  @keyframes swipe {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  .singleSlider {
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 4;
    align-content: center;

    justify-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;
    @include tablet {
      align-items: center;

      grid-template-rows: 76px 1fr;
      background: black;
      color: white;
    }
    &._active {
      opacity: 1;
      visibility: visible;
    }
    &-image {
      width: 100%;
      grid-column: left-cont / right-cont;
      @include tablet {
        grid-column: left-side / right-side;
        grid-row: 2;
        height: 100%;
      }
      @include phone {
        height: calc(100% - 100px);
      }
      height: 80vh;
      object-fit: cover;

      &._active {
        animation: 0.3s linear 0s 1 normal swipe;
      }
    }
    &-arrow {
      // height: 36px;
      // width: 36px;
      // border: 1px solid #979797;
      color: #1e2328;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      cursor: pointer;
      @include tablet {
        grid-row: 2;
        z-index: 1;
        color: white;
      }
      &._left {
        grid-column: left-side / span 1;
        justify-self: flex-start;
        transform: scale(-1);
        margin-left: 20px;
      }
      &._right {
        grid-column: span 1 / right-side;
        justify-self: flex-end;
        margin-right: 20px;
      }
    }
    &-close {
      grid-column: span 1 / right-cont;
      cursor: pointer;
      justify-self: flex-end;
      // margin: 10px 0 30px;
      @include tablet {
        margin: 10px 0;
        transform: scale(0.8);
      }
    }
    &-counter {
      grid-column: 7 / span 2;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin: 10px 0 30px;

      @include tablet {
        margin: 0;
        grid-column: left-cont / span 2;
      }
    }
  }
  // DRY!!
  .filters {
    &-inputGroup {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &._vertical {
        border-bottom: #ededed 2px solid;
        padding-bottom: 10px;
        .filters-input {
          width: 100%;
        }
        &:not(:first-child) {
          margin-bottom: 30px;
        }
      }
    }
    &-label {
      color: #c4c4c4;
      font-size: 16px;
      margin-bottom: 15px;
      @media (min-width: $tablet + 1) and (max-width: $middle) {
        font-size: 14px;
      }
    }
    &-input {
      font-size: 20px;
      font-weight: 600;
      width: 125px;
      height: 1em;
    }
  }
</style>
