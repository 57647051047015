<template lang="pug">
main.singlePage
  SingleIntro(:listing='listing', @enableSlider='toggleSlider(true)')
    .chooseDates.button(v-if='listing && !listing.sold', @click='formVisible = true') {{ listing.for == "for_rent" ? "choose dates" : "send request" }}
    .chooseDates.button(
      v-else,
      @click='$router.push({ name: "ListingsPage", params: { for: "for_sale" } })'
    ) go to listings for sale
  SingleDetails(:listing='listing')
  StickyForm(
    :listing='listing',
    :formVisible='formVisible',
    @closeForm='formVisible = false',
    @showPopup='popup = true'
  )
  progressive-background.singleFullimage(:src='largeImg', :placeholder='placeholderImg')
  SingleFeatures(:listing='listing')
  SingleBanner
  SingleAlso(:listing='listing')
  //- Debugger(:propsArray="[listing]")
  DrawLine._1._x._fullWidth
  DrawLine._2._y
  DrawLine._3._y
  DrawLine._4._y
  DrawLine._5._y

  SingleSlider(
    :images='listing && listing.Content.SliderImages',
    :class='{ _active: activeSlider }',
    @closeSlider='toggleSlider(false)'
  )
  .fullPopup(v-if='popup')
    .fullPopup-bg(@click='popup = false')
    .fullPopup-cont
      StickyForm(:listing='listing', :formVisible='true', :popup='true')
        .fullPopup-close(@click='popup = false'): SvgComponent(name='close')
  .whiteLayer
</template>

<script>
  import SingleIntro from './SingleIntro';
  import SingleDetails from './SingleDetails';
  import StickyForm from './StickyForm';
  import SingleFeatures from './SingleFeatures';
  import SingleRooms from './SingleRooms';
  import SingleBanner from './SingleBanner';
  import SingleAlso from './SingleAlso';
  // import Debugger from '@/components/Debugger'
  import DrawLine from '@/components/DrawLine.vue';
  import SingleSlider from './SingleSlider.vue';
  import SvgComponent from '@/components/SvgComponent.vue';

  export default {
    components: {
      SvgComponent,
      SingleIntro,
      SingleDetails,
      StickyForm,
      SingleFeatures,
      SingleRooms,
      SingleBanner,
      SingleAlso,
      // Debugger,
      DrawLine,
      SingleSlider,
    },
    name: 'SinglePage',
    data() {
      return {
        popup: false,
        listing: null,
        activeSlider: false,
        formVisible: this.$store.getters.desktop,
        // imgLoaded: false
      };
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.checkIntroScreen);
    },
    methods: {
      checkIntroScreen() {
        if (window.scrollY < window.innerHeight - 100) {
          document.querySelector('#fb-root').style.display = 'none';
        } else {
          document.querySelector('#fb-root').style.display = 'block';
        }
      },
      toggleSlider(param) {
        this.activeSlider = param;
        document.querySelector('#app').style.overflowY = param ? 'hidden' : 'visible';
        document.body.style.overflowY = param ? 'hidden' : 'visible';
      },
    },

    computed: {
      placeholderImg() {
        return this.$hostname + this.listing?.Content.FullPageImage2?.formats.thumbnail?.url;
        // return 'https://api.agencyian.com/uploads/thumbnail_22_Santosha_Villa_Estate_Anguilla_Sunset_bd1dcda400.jpg'
      },
      largeImg() {
        return (
          this.$hostname +
          (this.listing?.Content.FullPageImage2?.formats.large?.url ||
            this.listing?.Content.FullPageImage2?.url)
        );
        // return 'https://api.agencyian.com/uploads/22_Santosha_Villa_Estate_Anguilla_Sunset_bd1dcda400.jpg'
      },
    },
    watch: {
      '$route.params.id': async function () {
        this.listing = await this.$store.dispatch('getListingById', this.$route.params.id);
        if (this.listing.sold) {
          document.querySelector('#tomap').href = `${this.$frontname}map/`;
        }
      },
      formVisible: function (param) {
        document.querySelector('#app').style.overflowY = param ? 'hidden' : 'visible';
        document.body.style.overflowY = param ? 'hidden' : 'visible';
      },
    },
    async mounted() {
      this.checkIntroScreen();
      window.addEventListener('scroll', this.checkIntroScreen);

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
      }, 100);

      // window.addEventListener('scroll', this.calcProp);
      this.listing = await this.$store.dispatch('getListingById', this.$route.params.id);
      if (!this.listing) {
        this.$router.push({ name: '404' });
      }
      // shitcode

      // Draws
      // NOT DRY on SinglePage
      const desktop = window.matchMedia('(min-width: 769px)').matches;
      const windowHeight = document.documentElement.clientHeight;
      const halfWindowHeight = document.documentElement.clientHeight / 2;
      const verticalTrigger = desktop ? halfWindowHeight : halfWindowHeight + windowHeight / 3;
      function documentScrolled() {
        YDrawElements.forEach((el, i) => {
          if (el.getBoundingClientRect().top < verticalTrigger) {
            el.classList.add('_active');
            if (i == YDrawElements.length - 1) {
              window.removeEventListener('scroll', documentScrolled);
              console.log('documentScrolled clear');
            }
          }
        });
      }
      const YDrawElements = [...document.querySelectorAll('.drawLine')];
      window.addEventListener('scroll', documentScrolled);
    },
  };
</script>

<style lang="scss">
  .chooseDates {
    // position: fixed;
    // bottom: 20px;

    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    display: none;
    z-index: 1;
    @include tablet {
      display: block;
      left: 50px;
      right: 50px;
    }
    @include phone {
      display: block;
      left: 0px;
      // left: 16px;
      // right: 16px;
    }
  }

  .singlePage {
    .fullPopup {
      position: fixed;
      z-index: 3;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .stickyForm {
        margin: auto;
      }

      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
      }
      &-cont {
        // padding: 20px;
        // background: white;
        position: relative;
      }
      &-close {
        position: absolute;
        right: 20px;
        top: 25px;
        z-index: 111;
        cursor: pointer;
        // color: black
      }
    }
    .drawLine {
      &._1 {
        grid-row: 8;
      }
      &._2 {
        grid-row: 8;
        grid-column: 2;
      }
      &._3 {
        grid-row: 8;
        grid-column: 6;
      }
      &._4 {
        grid-row: 7 / 9;
        grid-column: 10;
        @include middle {
          grid-row: 8 / 9;
        }
      }
      &._5 {
        grid-row: 8;
        grid-column: right-cont;
      }
      @include tablet {
        display: none;
      }
    }
  }
  .singleFullimage {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-size: cover;
    background-position: center;
    scroll-behavior: auto;
    // background-image: url(../../assets/images/bgsingle2.jpg);
  }
</style>
