<template lang="pug">
section.singleBanner
  .singleBanner-bg
  .singleBanner-cont 
    h2.singleBanner-title Over 35 years experience<br> in the Turks & Caicos
    .singleBanner-divider
    .singleBanner-text A trusted resource ensuring that your vacation exceeds your dreams
</template>

<script>
  export default {};
</script>

<style lang="scss">
  .singleBanner {
    display: contents;
    &-bg {
      background: $red;
      height: 350px;
      @include middle {
        height: 290px;
      }
      @include tablet {
        height: auto;
      }
    }
    &-cont {
      color: white;
      display: flex;
      align-items: center;
      @include tablet {
        flex-direction: column;
        padding: 64px 0;
        text-align: center;
      }
    }
    &-title {
      font-size: 64px;
      line-height: 77px;
      @include middle {
        font-size: 36px;
        line-height: 43px;
      }
      @include tablet {
        font-size: 40px;
        line-height: 48px;
      }
    }
    &-divider {
      height: calc(100% - 200px);
      width: 2px;
      background: white;
      margin: 0 100px;
      @include middle {
        margin: 0 auto;
      }
      @include tablet {
        display: none;
      }
    }
    &-text {
      width: 204px;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      @include middle {
        font-size: 18px;
        line-height: 22px;
      }
      @include tablet {
        width: auto;
        margin-top: 40px;
      }
    }
  }
</style>
