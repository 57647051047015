<template lang="pug">
section.singleAlso
  .singleAlso-titlebox: h2.singleAlso-title May also interest you
  .singleAlso-item(v-for='(item, i) in set', :class='"_" + (i + 1)'): ListingItem(:listing='item')
</template>

<script>
  // листинг в компонент
  import SvgComponent from '@/components/SvgComponent';
  import ListingItem from '@/components/ListingItem.vue';

  export default {
    props: ['listing'],
    data() {
      return {
        // listings: [],
        set: new Set(),
      };
    },
    components: {
      SvgComponent,
      ListingItem,
    },
    watch: {
      async listing() {
        // shitcode - do backend route
        if (this.listing?.for) {
          const allListingsForPurpose = await this.$store.dispatch('getListings', {
            purpose: this.listing.for,
          });
          const set = new Set();
          for (let i = 0; set.size < 3 && i < 100; i++) {
            const pickedRandomListing =
              allListingsForPurpose[Math.floor(Math.random() * allListingsForPurpose.length)];
            if (pickedRandomListing.id != this.listing.id) {
              set.add(pickedRandomListing);
            }
          }
          this.set = set;
          console.warn('set', set);
          this.$store.commit('pageLoaded');
        }
      },
    },
  };
</script>

<style lang="scss">
  .singleAlso {
    // tepm shit!
    @include tablet {
      &-item:not(:last-child) {
        display: none;
      }
    }

    display: contents;
    &-titlebox {
      margin-top: 150px;
      @include tablet {
        margin-top: 56px;
      }
    }
    &-title {
      font-size: 64px;
      line-height: 77px;
      font-weight: bold;
      margin-bottom: 60px;
      @include tablet {
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        margin-bottom: 34px;
      }
    }
    &-item {
      // border: 1px solid black;
      height: 870px;
      display: flex;
      flex-direction: column;
      @include middle {
        height: 730px;
      }
      @include small {
        height: 670px;
      }
      &._1 {
        grid-column: left-cont / span 4;
        padding-top: 80px;
      }
      &._2 {
        grid-column: 6 / span 4;
        padding-top: 160px;
      }
      &._3 {
        padding-top: 240px;
        grid-column: span 4 / right-cont;
      }
      @include tablet {
        padding-top: 0 !important;
        grid-column: left-side / right-side !important;
        height: auto;
        padding-bottom: 55px;
      }
    }
    &-image {
      height: 386px;
      width: 386px;
      object-fit: cover;
      user-select: none;
    }
  }
</style>
