<template lang="pug">
.stickyForm(:style='{ display: formVisible ? "block" : "none" }')
  .stickyForm-header
    .stickyForm-headerLogo: SvgComponent(name='logo')
    .stickyForm-headerClose(@click='$emit("closeForm"); step = 1; showPopup = null'): SvgComponent(
      name='close'
    )
  .stickyForm-form(v-if='listing')
    h2.stickyForm-title(:class='{ _popup: popup }') {{ listing.title }}
    .stickyForm-subtitle {{ listing.address }}
    .stickyForm-props
      .stickyForm-prop(v-if='listing.beds')
        SvgComponent(name='bed')
        span {{ listing.beds }}
      .stickyForm-prop(v-if='listing.baths')
        SvgComponent(name='bath')
        span {{ listing.baths }}{{ halfBathRender() }}
      .stickyForm-prop(v-if='listing.for == "for_rent" && listing.maxGroup')
        SvgComponent(name='group')
        span {{ listing.maxGroup }}

    //- SOLD
    template(v-if='listing.sold') 
      router-link.button._black(:to='{ name: "ListingsPage", params: { for: "for_sale" } }') go to listings for sale
      router-link.button._blackReverse(:to='{ name: "SoldPage" }') see other sold listings

    //- SALE
    template(v-if='listing.for == "for_sale" && !listing.sold')
      .stickyForm-divider
      .stickyForm-subtitle Send request for details
      //- выделить в компонент
      .filters-inputGroup._vertical(:class='{ _error: $v.name.$error }')
        label.filters-label Name
        input.filters-input(placeholder='Full Name', v-model.trim='$v.name.$model')
        .error(v-if='$v.name.$error && !$v.name.required') Field is required
      .filters-inputGroup._vertical(:class='{ _error: $v.email.$error }')
        label.filters-label E-mail
        input.filters-input(placeholder='Your e-mail', v-model.trim='$v.email.$model')
        .error(v-if='$v.email.$error && !$v.email.required') Field is required
        .error(v-if='$v.email.$error && !$v.email.email') Email is invalid

      .button._submit(@click='submit', :class='{ _loading: submitStatus == "loading..." }') {{ submitStatus }}

    //- POPUP
    template(v-if='popup')
      .stickyForm-subtitle Send request for details

      .filters-inputGroup._vertical(:class='{ _error: $v.name.$error }')
        label.filters-label Name
        input.filters-input(placeholder='Full Name', v-model.trim='$v.name.$model')
        .error(v-if='$v.name.$error && !$v.name.required') Field is required
      .filters-inputGroup._vertical(:class='{ _error: $v.email.$error }')
        label.filters-label E-mail
        input.filters-input(placeholder='Your e-mail', v-model.trim='$v.email.$model')
        .error(v-if='$v.email.$error && !$v.email.required') Field is required
        .error(v-if='$v.email.$error && !$v.email.email') Email is invalid

    //- RENT
    template(v-if='listing.for == "for_rent"')
      .stickyForm-divider(v-if='!popup')
      //- .stickyForm-subtitle Send request for details
      //- выделить в компонент

      //- STEP 1
      template(v-if='step == 1') 
        .filters-inputGroup._vertical._double
          .filters-inputGroup
            label.filters-label Start
            .filters-input._date(@click='showPopup = "datapicker"') {{ dateFrom ? formatDate(dateFrom) : "Add date" }}
          span —
          .filters-inputGroup._vertical
            label.filters-label End
            .filters-input._date(@click='showPopup = "datapicker"') {{ dateTo ? formatDate(dateTo) : "Add date" }}
          .curp(@click='showPopup = showPopup == "datapicker" ? null : "datapicker"'): SvgComponent(
            name='calendar',
            :style='{ color: showPopup == "datapicker" ? "#d41e34" : "black" }'
          )

        .filters-inputGroup._vertical._double(
          v-if='$store.getters.desktop',
          :class='{ _notPopup: !popup }'
        )
          .filters-inputGroup
            label.filters-label Adults
            .filters-input
              div(@click='customChange("adults", -1, 0)'): SvgComponent(
                name='minus',
                :style='{ color: adults > 1 ? "black" : "lightgrey" }'
              )
              .filters-actionCounter {{ adults }} adults
              div(@click='customChange("adults", 1, 0)'): SvgComponent(name='plus')

          .filters-divider(:class='{ _popup: popup }')
          .filters-inputGroup
            label.filters-label Children
            .filters-input
              div(@click='customChange("children", -1)'): SvgComponent(
                name='minus',
                :style='{ color: children ? "black" : "lightgrey" }'
              )
              .filters-actionCounter {{ children }} children
              div(@click='customChange("children", 1)'): SvgComponent(name='plus')

        .filter-phoneCounterRows(v-else)
          .filter-phoneCounterRow
            label.filters-label Adults
            .filters-input
              .filter-phoneCounterAction(@click='customChange("adults", -1, 0)'): SvgComponent(
                name='minus2'
              )
              .filter-phoneCounterNumber {{ adults }}
              .filter-phoneCounterAction(@click='customChange("adults", 1, 0)'): SvgComponent(
                name='plus2'
              )
          .filter-phoneCounterRow
            label.filters-label Children
            .filters-input
              .filter-phoneCounterAction(@click='customChange("children", -1)'): SvgComponent(
                name='minus2'
              )
              .filter-phoneCounterNumber {{ children }}
              .filter-phoneCounterAction(@click='customChange("children", 1)'): SvgComponent(
                name='plus2'
              )

      //- STEP 2 (FOR RENT ON MOBILE)
      template(v-if='step == 2')
        .confirmation
          .confirmation-item
            .confirmation-key Dates
            .confirmation-value {{ formatDate(dateFrom) }} - {{ formatDate(dateTo) }}
            .confirmation-edit(@click='showPopup = "datapicker"'): SvgComponent(name='edit')
          .confirmation-item
            .confirmation-key Guests
            .confirmation-value {{ adults }} Adults, {{ children }} Children
            .confirmation-edit(@click='step = 1'): SvgComponent(name='edit')

        .filters-inputGroup._vertical(:class='{ _error: $v.name.$error }')
          label.filters-label Name
          input.filters-input(placeholder='Full Name', v-model.trim='$v.name.$model')
          .error(v-if='$v.name.$error && !$v.name.required') Field is required
        .filters-inputGroup._vertical(:class='{ _error: $v.email.$error }')
          label.filters-label E-mail
          input.filters-input(placeholder='Your e-mail', v-model.trim='$v.email.$model')
          .error(v-if='$v.email.$error && !$v.email.required') Field is required
          .error(v-if='$v.email.$error && !$v.email.email') Email is invalid
      .button._next(
        v-if='!$store.getters.desktop && listing.for == "for_rent" && step == 1',
        @click='changeStep'
      ) next step
      .button._submit(
        v-else,
        :class='{ _loading: submitStatus == "loading..." }',
        @click='onSubmit'
      ) {{ submitStatus }}
    .stickyForm-badge(v-if='listing.sold') sold

    slot
    v-range-selector.datapicker._listing(
      :start-date.sync='dateFrom',
      :end-date.sync='dateTo',
      :class='{ _active: showPopup == "datapicker", _popup: popup }'
    )
</template>

<script>
  import SvgComponent from '@/components/SvgComponent';
  import { validationMixin } from 'vuelidate';
  import { required, email } from 'vuelidate/lib/validators';
  // import VRangeSelector from 'vuelendar/components/vl-range-selector';
  import VRangeSelector from '@/components/vuelendar/components/vl-range-selector';

  export default {
    mixins: [validationMixin],
    data() {
      return {
        submitStatus: 'send request',
        adults: 1,
        children: 0,
        showPopup: null,
        step: 1,

        name: '',
        email: '',
        dateTo: '2020-12-10',
        dateFrom: '2020-12-11',
      };
    },
    validations: {
      name: { required },
      email: { required, email },
    },
    props: ['listing', 'formVisible', 'popup'],
    components: { SvgComponent, VRangeSelector },
    watch: {
      dateTo() {
        if (this.dateTo) {
          this.showPopup = null;
        }
      },
    },
    methods: {
      changeStep() {
        this.step = this.step + 1;
      },

      onSubmit() {
        if (this.step == 2) {
          this.submit();
        } else if (this.listing.for == 'for_rent' && !this.popup) {
          this.$emit('showPopup');
        } else {
          this.submit();
        }
      },
      // DRY
      halfBathRender() {
        if (!this.listing.halfbaths) {
          return '';
        }
        if (this.listing.halfbaths == 1) {
          return '.5';
        }
        if (this.listing.halfbaths > 1) {
          return ` + ${this.listing.halfbaths}half`;
        }
      },
      formatDate(date) {
        let dateObj = new Date(date);
        return dateObj.toLocaleString('en', { weekday: 'short', month: 'short', day: 'numeric' });
      },
      customChange(state, param, minmin = -1) {
        if (this[state] + param > minmin) {
          this[state] += param;
        }
      },

      async submit() {
        console.log(this.submitStatus);
        if (this.submitStatus == 'OK' || this.submitStatus == 'loading...') {
          return;
        }
        console.log('submit!');
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.log('form is invalid!');
          this.submitStatus = 'invalid form';
        } else {
          this.submitStatus = 'loading...';
          const res = await this.$store.dispatch('sendEmail', {
            name: this.name,
            email: this.email,
            sold: this.listing.sold,
            adults: this.adults,
            children: this.children,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            listingName: this.listing.title,
            listingId: this.listing.id,
          });
          console.log(res);
          this.submitStatus = res.statusText;
          await this.$emit('closeForm');
          this.$router.push({ name: 'SuccessPage' });
        }
      },
    },
  };
</script>
<style src="vuelendar/scss/vuelendar.scss" lang="scss"></style>
<style lang="scss" scoped>
  .filter-phoneCounterAction {
    border: 2px #757575 solid;
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 64px;
    flex-shrink: 0;
  }
  .filter-phoneCounterNumber {
    border-top: 2px #ededed solid;
    border-bottom: 2px #ededed solid;
    width: 100%;
    text-align: center;
    width: 100%;
    height: 64px;
    line-height: 64px;
  }
  .filter-phoneCounterRows {
    @include gap(30px, col);
  }
  .filter-phoneCounterRow {
    //   @include nophone {
    //   display: none;
    // }
    display: flex;
    align-items: center;
    .aicjcb {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    label {
      margin: 0;
      width: 100px;
    }
  }
  .confirmation {
    padding: 6px 0 16px;
    @include gap(16px, col);
    border-bottom: 2px solid #ededed;
    margin-bottom: 24px;
    font-size: 16px;
  }
  .confirmation-item {
    display: flex;
  }

  .confirmation-key {
    min-width: 49px;
    color: #c4c4c4;
    margin-right: 24px;
  }
  .confirmation-edit {
    margin-left: auto;
  }
  .filters-inputGroup {
    position: relative;
    &._error {
      border-color: $red !important;
    }
    .error {
      position: absolute;
      bottom: -1.5em;
      font-size: 16px;
      line-height: 19px;
      color: $red;
    }
  }

  .stickyForm {
    position: relative;
    margin-top: 150px;
    margin-bottom: 80px;

    @include tablet {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      z-index: 4;
      display: none;
      // overflow: scroll;
    }
    &-badge {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-transform: lowercase;
      color: white;
      background: $red;
      padding: 20px 25px;
      position: absolute;
      right: -20px;
      top: 20px;
    }
    &-header {
      display: none;
      color: white;
      background: black;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      height: 72px;
      &Logo {
        transform: scale(0.7);
        transform-origin: left center;
      }
      &Close {
        transform: scale(0.7);
        transform-origin: right center;
      }
      @include tablet {
        display: flex;
      }
    }
    &-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      // &._popup {
      max-width: 350px;
      // }
      @media (min-width: $tablet + 1) and (max-width: $middle) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &-form {
      padding: 30px;
      position: sticky;
      z-index: 2;
      top: 170px;
      width: 100%;
      background: white;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

      @include tablet {
        position: static;
        height: calc(100% - 72px);
        display: flex;
        flex-direction: column;
        // overflow: scroll;
        .button {
          margin-top: auto;
        }
      }
    }
    &-subtitle {
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      margin: 10px 0 20px;
      @media (min-width: $tablet + 1) and (max-width: $middle) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &-props {
      display: flex;
      @include gap(40px, row);
    }

    &-prop {
      display: flex;
      @include gap(10px, row);
      align-items: center;
      @media (min-width: $tablet + 1) and (max-width: $middle) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &-divider {
      height: 2px;
      width: 100%;
      flex-shrink: 0;
      background: #ededed;
      margin: 30px 0;
      @include tablet {
        margin: 10px 0 !important;
      }
      @media (min-width: $tablet + 1) and (max-width: $middle) {
        margin: 20px 0;
      }
    }
  }
  .filters-inputGroup._vertical._double {
    user-select: none;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    & > .filters-inputGroup {
      margin: 0 !important;
      padding: 0 !important;
      border: 0;
    }
    .filters-input {
      display: flex;
    }
    .filters-actionCounter {
      // margin: 0 20px;
      width: 120px;
      text-align: center;
      white-space: nowrap;
      @include middle {
        width: 100%;
      }
      @include tablet {
        white-space: nowrap;
        margin: 0 10px;
      }
    }

    .filters-divider {
      width: 2px;
      // height: 100%;
      align-self: stretch;
      margin: 0 auto;
      // flex-shrink: 2;
      background: #ededed;
      &._popup {
        margin: 0px 30px;
      }
    }

    &._notPopup {
      @include middle {
        flex-direction: column;
        .filters-inputGroup {
          flex-direction: row;
          width: 100%;
          align-items: center;
          &:first-child {
            padding-bottom: 14px !important;
            border-bottom: #ededed 2px solid;
          }
          &:last-child {
            padding-top: 26px !important;
          }
        }
        .filters-label {
          // margin-right: auto;
          width: 130px;
          margin-bottom: 0;
        }
        .filters-input {
          // width: auto;
          text-align: center;
          white-space: nowrap;
        }
      }
    }
  }
</style>
