<template lang="pug">
section.singleDetails
  h2.singleDetails-title Details
  .singleDetails-singleParams.singleParams
    .singleParams-section._price(v-if='listing && listing.priceValue')
      .singleParams-key {{ listing.priceValue | toCurrency }}
      .singleParams-value._price
        span PRICE
        SvgComponent(name='tip', tabindex='0')
        .singleParams-tip Prices are subject to tax and service charges
    .singleParams-section(v-if='listing && listing.beds')
      .singleParams-key {{ listing && listing.beds }}
      .singleParams-value BEDS
    .singleParams-section(v-if='listing && listing.baths')
      .singleParams-key {{ listing && listing.baths }}{{halfBathRender()}}
      .singleParams-value TOTAL BATHS
    .singleParams-section(v-if='listing && listing.for == "for_rent"')
      .singleParams-key {{ listing && listing.maxGroup }}
      .singleParams-value MAX GROUP
    .singleParams-section(v-if='listing && listing.sqft')
      .singleParams-key {{ listing && listing.sqft }}
      .singleParams-value SQFT
    .singleParams-section(v-if='listing && listing.acres')
      .singleParams-key {{ listing && listing.acres }}
      .singleParams-value ACRES  
  p.singleDetails-text {{ listing && listing.Content.details }}
</template>

<script>
  import SvgComponent from '@/components/SvgComponent';

  

  export default {
    components: {
      SvgComponent,
    },
    props: ['listing'],
    methods:{
      halfBathRender() {
        
        if (!this.listing.halfbaths) {
          return '';
        }
        if (this.listing.halfbaths == 1) {
          return '.5';
        }
        if (this.listing.halfbaths > 1) {
          return ` + ${this.listing.halfbaths}half`;
        }
      },
    }
  };
</script>

<style lang="scss">
  .singleDetails {
    padding: 150px 0;
    display: flex;
    flex-direction: column;
    @include gap(50px, col);
    position: relative;
    &-title {
      font-size: 64px;
      line-height: 77px;
      @include middle {
        font-size: 36px;
        line-height: 43px;
      }
      @include tablet {
        font-size: 36px;
        line-height: 43px;
      }
    }
    &-text {
      font-size: 16px;
      line-height: 28px;
    }
    @include middle {
      padding: 100px 0;
    }
    @include tablet {
      align-items: center;
      padding: 64px 0 56px;
    }
  }
  .singleParams {
    display: flex;
    @include tablet {
      flex-direction: column;
      margin-top: 0 !important;
    }
    &-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 120px;
      padding: 24px 0;
      // padding: 0 10px;
      @include gap(15px, col);
      @include middle {
        min-width: auto;
        width: 100%;
        @include gap(10px, col);
        padding: 24px 0;
      }
      &._price {
        min-width: 220px;
        position: relative;
        .svg-tip:hover + .singleParams-tip {
          opacity: 1;
        }
        @include middle {
          min-width: auto;
        }
        @include tablet {
          min-width: 150px;
          position: static;
        }
      }

      &:not(:first-child) {
        border-left: #ededed 2px solid;
        @include tablet {
          border-left: 0;
          border-top: #c4c4c4 2px solid;
        }
      }
    }
    &-key {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      @include tablet {
        font-size: 24px;
      }
      @include middle {
        font-size: 16px;
      }
    }
    &-value {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      @include middle {
        font-size: 10px;
      }
      &._price {
        display: flex;
        @include gap(10px, row);
        align-items: center;
      }
      @include tablet {
        font-size: 16px;
      }
    }
    &-tip {
      position: absolute;
      white-space: nowrap;
      // bottom: -20px;
      transform: translateY(100%);
      left: -10px;
      padding: 20px;
      font-size: 16px;
      line-height: 19px;
      background: white;
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
      opacity: 0;
      transition: opacity 0.2s;
      &::after {
        content: '';
        position: absolute;
        left: 123px;
        border: 10px solid transparent;
        border-bottom: 10px solid white;
        top: -19px;
        @include tablet {
          left: 54%;
        }
      }
      @include tablet {
        bottom: auto;
        left: 0;
        width: 100%;
        text-align: center;
        margin: 0 !important;
        // white-space: normal;
      }
    }
  }
</style>
