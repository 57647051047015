<template lang="pug">
section.singleFeatures
  h2.singleFeatures-title(v-if='listing && listing.Content.features') Features & amenities
  .singleFeatures-featuresList.featuresList(v-if='listing && listing.Content.features') {{ listing && listing.Content.features }}

  h2.singleFeatures-title(v-if='listing && listing.Content.rooms') Bedroom & bathroom
  .featuresRooms(v-if='listing && listing.Content.rooms', v-html='marked(listing.Content.rooms)')
</template>

<script>
  import marked from 'marked';

  export default {
    props: ['listing'],
    methods: {
      marked,
    },
  };
</script>

<style lang="scss">
  .singleFeatures {
    padding: 150px 0;
    display: flex;
    flex-direction: column;
    @include middle {
      padding: 100px 0;
    }
    @include tablet {
      padding: 56px 0;
    }
    &-title {
      font-size: 64px;
      line-height: 77px;
      margin-bottom: 50px;
      @include middle {
        font-size: 36px;
        line-height: 43px;
      }
      @include tablet {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 32px;
      }
    }
    .featuresList {
      white-space: pre-wrap;
      margin-bottom: 150px;
      font-size: 16px;
      line-height: 28px;
      column-count: 2;
      @include middle {
        margin-bottom: 100px;
      }
      @include tablet {
        margin-bottom: 56px;
      }
      @include phone {
        column-count: 1;
      }
    }
    .featuresRooms {
      font-size: 16px;
      line-height: 28px;
      white-space: pre-wrap;
      u {
        font-weight: bold;
        color: $red;
        text-decoration: none;
      }
      // display: flex;
      // flex-direction: column;
    }
  }
</style>
