<template lang="pug">
.singleIntro
  //- .singleIntro-bg(:style="{backgroundImage: imgUrl}") 
  progressive-background.singleIntro-bg(:src='largeImg', :placeholder='placeholderImg') 
  .singleIntro-cont
    .button._transparent(style='visibility: hidden')
    h1.singleIntro-title {{ listing && listing.title }}
    .buttoncontainer
      .button._transparent(
        @click='$emit("enableSlider")',
        :style='{ visibility: listing && listing.Content.SliderImages[0] ? "visible" : "hidden" }'
      ) all photos
      slot
    .singleIntro-sold(v-if='listing && listing.sold') just sold
</template>

<script>
  export default {
    data() {
      return {
        // imgLoaded: false
      };
    },
    props: ['listing'],
    computed: {
      placeholderImg() {
        return this.$hostname + this.listing?.Content.FullPageImage1?.formats.thumbnail?.url;
        // return 'https://api.agencyian.com/uploads/thumbnail_22_Santosha_Villa_Estate_Anguilla_Sunset_bd1dcda400.jpg'
      },
      largeImg() {
        return (
          this.$hostname +
          (this.listing?.Content.FullPageImage1?.formats.large?.url ||
            this.listing?.Content.FullPageImage1?.url)
        );
        // return 'https://api.agencyian.com/uploads/22_Santosha_Villa_Estate_Anguilla_Sunset_bd1dcda400.jpg'
      },
    },
  };
</script>

<style lang="scss">
  .buttoncontainer {
    display: contents;
    @include tablet {
      display: block;
      width: 100%;
      @include gap(10px, col);
    }
  }
  .singleIntro {
    display: contents;
    &-sold {
      font-size: 36px;
      line-height: 43px;
      position: absolute;
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-transform: uppercase;
      font-family: Proto Grotesk Web;
      @include tablet {
        font-size: 24px;
        line-height: 29px;
        // margin-top: -2em;
        margin-bottom: 10px;
      }
    }
    &-bg {
      height: 100vh !important;
      height: calc(var(--vh, 1vh) * 100) !important;
      min-height: 100vh;
      min-height: -webkit-fill-available;
      // height: env(safe-area-inset-left, 100vh);
      // background: black;
      // background: red;
      // background-image: url(../../assets/images/bgsingle.jpg);
      background-size: cover;
      background-position: center;
      // object-fit: cover;
      // object-position: center;
      // width: 100%;
    }
    &-cont {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 80px;
      @include tablet {
        // justify-content: flex-end;
        align-items: center;
        // padding-bottom: 40px;
        flex-direction: column;
        // padding-bottom: 40px;
        margin-bottom: 16px;
        .button {
          width: 100%;
          // margin-bottom: 90px;
        }
      }
    }
    &-title {
      font-size: 36px;
      font-family: Proto Grotesk Web;
      line-height: 43px;
      color: white;
      @include tablet {
        font-weight: bold;
        font-size: 24px;
      }
    }
    & > .progressive-background > span {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }
</style>
